import firebase from "firebase/app"
import React, { createContext, FC, useCallback, useContext } from "react"
import { noSsr } from "../NoSsrContext"
import { useUser } from "./FirebaseContext"
import { BigMessageSpinner } from "../BigMessageSpinner"
import { UnAuthenticatedAlert } from "./UnAuthenticatedAlert"

const AuthenticatedContext = createContext<{
  app: firebase.app.App
  user: firebase.User
} | null>(null)

export const AuthenticatedProvider: FC<{
  app: firebase.app.App
  user: firebase.User
}> = ({ children, app, user }) => (
  <AuthenticatedContext.Provider value={{ app, user }}>
    {children}
  </AuthenticatedContext.Provider>
)

/** Show message if user isn't logged in. Redirect could be an option, but it can be jarring since you may not see the page you were trying to get to and why it's not allowed. */
export const Authenticated: FC = noSsr(({ children }) => {
  const { app, user, isLoading } = useUser()

  if (!app || isLoading) {
    return <BigMessageSpinner message="Authenticating..." />
  }

  if (user) {
    return (
      <AuthenticatedProvider app={app} user={user}>
        {children}
      </AuthenticatedProvider>
    )
  }

  return <UnAuthenticatedAlert />
})

export function useAuthenticated() {
  const stuff = useContext(AuthenticatedContext)

  if (stuff === null) {
    throw new Error(
      "`useAuthenticated()` must be used within an `Authenticated` provider"
    )
  }

  const { app, user } = stuff

  const userDoc = useCallback(
    () => app.firestore().collection("users").doc(user.uid),
    [app, user]
  )

  return {
    app,
    user,
    userDoc,
  }
}
