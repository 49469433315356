import React, { FC, useContext, useEffect } from "react"
import { BigMessageSpinner } from "../BigMessageSpinner"
import { AuthenticatedProvider } from "./Authenticated"
import { SharedFirebaseContext } from "./FirebaseContext"
import { UnAuthenticatedAlert } from "./UnAuthenticatedAlert"

type AuthenticatedProps = {
  loading?: JSX.Element
  auth?: JSX.Element
  noAuth?: JSX.Element
}

export const Authenticated2: FC<AuthenticatedProps> = ({
  children,
  loading,
  auth,
  noAuth,
}) => {
  const { init, app, isLoadingUser, user } = useContext(SharedFirebaseContext)

  useEffect(init, [init])

  if (!app || isLoadingUser) {
    return loading ?? <BigMessageSpinner message="Authenticating..." />
  }

  if (app && user) {
    return (
      <AuthenticatedProvider app={app} user={user}>
        {auth ?? children}
      </AuthenticatedProvider>
    )
  }

  return noAuth ?? <UnAuthenticatedAlert />
}
