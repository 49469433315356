import { Box } from "@chakra-ui/react"
import { css } from "@emotion/react"
import React, { FC } from "react"

export const DisabledWhenLoading: FC<{
  isLoading: boolean
}> = ({ isLoading, children }) => {
  return (
    <Box
      position="relative"
      css={
        isLoading
          ? css`
              transition-property: opacity;
              transition-duration: 300ms;
              pointer-events: none;
              opacity: 0.6;
            `
          : css`
              transition-property: opacity;
              transition-duration: 300ms;
            `
      }
    >
      {children}
    </Box>
  )
}
