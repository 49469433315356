import React, { ErrorInfo, ReactNode } from "react"

type Props = { children: ReactNode; showReset?: boolean }
type State = { hasError: boolean }

// Code stolen from:
// https://reactjs.org/docs/error-boundaries.html
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error("Uncaught error:", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      // Styling using inline styles because we don't want errors while we're
      // handling errors. Good to reduce dependencies like this.
      return (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <div
          style={{
            textAlign: "center",
            // marginLeft: "auto",
            // marginRight: "auto",
            fontFamily: "sans-serif",
            backgroundColor: "#ff4444",
            color: "white",
            padding: ".4em .6em",
          }}
        >
          ☠️ Broken Component
          {this.props.showReset && (
            <button
              onClick={() => this.setState({ hasError: false })}
              style={{
                marginLeft: ".5em",
                fontWeight: "bold",
                backgroundColor: "white",
                color: "black",
                padding: "0 .4em",
                borderRadius: ".2em",
                border: "0",
              }}
            >
              Reset
            </button>
          )}
        </div>
      )
    }

    return this.props.children
  }
}
