import { Button, VStack } from "@chakra-ui/react"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React from "react"

export function DebugMixpanel() {
  const mixpanel = useMixpanel()

  function handleTrack() {
    mixpanel.track("example.event", null, { send_immediately: true })
    alert("sent ")
  }

  function setValue(value: boolean) {
    mixpanel.people.set({
      "example.person_value": value,
    })
  }

  return (
    <VStack spacing={2} align="stretch">
      <Button onClick={handleTrack}>Mixpanel Immediate Event</Button>
      <Button onClick={() => setValue(true)}>Set Person Value</Button>
      <Button onClick={() => setValue(false)}>Unset Person Value</Button>
    </VStack>
  )
}
