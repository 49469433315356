import { Button, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { ErrorBoundary } from "../ErrorBoundary"
import { ThrowError } from "../ThrowError"

function CrashComponent() {
  const [showError, setShowError] = useState(false)

  return (
    <>
      <Button onClick={() => setShowError(true)}>Error in render</Button>
      {showError && <ThrowError error={new Error("Test error boundary")} />}
    </>
  )
}

export function DebugSentry() {
  return (
    <VStack spacing={2} align="stretch">
      <Button onClick={() => methodDoesNotExist()}>Error in callback</Button>
      <ErrorBoundary>
        <CrashComponent />
      </ErrorBoundary>
    </VStack>
  )
}
