module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"2d9da53fefdc20d05d9f47ad8f6cfc90","enableOnDevMode":true,"pageViews":{"/404":"Page 404 view"}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://82f96da2bf3a4798a403a47cbb4b4704@o523235.ingest.sentry.io/5635386","tracesSampleRate":1},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
