import { useCallback, useEffect, useState } from "react"
import { config } from "../../config"
import { useAuthenticated } from "../auth/Authenticated"

type Prefs = {
  vocabularySize: number
  timerLengthSeconds: number
}

const defaultPrefs: Prefs = {
  vocabularySize: config.RANDOM_TOP_WORDS,
  timerLengthSeconds: config.TIMER_LENGTH,
}

export function usePrefsOld() {
  const { userDoc } = useAuthenticated()
  const [prefs, setPrefs] = useState<Prefs>(defaultPrefs)
  const [isLoading, setIsLoading] = useState(true)

  // Load and watch prefs for changes
  useEffect(() => {
    const unsubscribe = userDoc().onSnapshot(
      { includeMetadataChanges: true },
      doc => {
        if (doc.data()?.preferences) {
          setPrefs({ ...defaultPrefs, ...doc.data()?.preferences })
        }
        setIsLoading(doc.metadata.hasPendingWrites)
      }
    )
    return unsubscribe
  }, [userDoc])

  // Update prefs via Firebase, which will then populate the React state
  const updatePrefs = useCallback(
    (prefs: Partial<Prefs>) => {
      setIsLoading(true)
      userDoc().set({ preferences: prefs }, { merge: true })
    },
    [userDoc]
  )

  const resetPrefs = useCallback(() => {
    userDoc().set({ preferences: defaultPrefs }, { merge: true })
  }, [userDoc])

  return {
    isLoading,
    // error,
    prefs,
    updatePrefs,
    resetPrefs,
  }
}
