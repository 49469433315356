import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Text,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React, { FC } from "react"
import { Authenticated2 } from "../auth/Authenticated2"
import { NoSsr } from "../NoSsrContext"
import { Preferences } from "./Preferences"
import { DebugAuth } from "./DebugAuth"
import { useDebug } from "./DebugContext"
import { DebugMixpanel } from "./DebugMixpanel"
import { DebugSentry } from "./DebugSentry"

function Pages() {
  const links = [
    "/debug",
    "/debug/json",
    "/debug/theme",
    "/debug/base16",
    "/debug/firebase-event-listeners",
    "/debug/on-demand-firebase",
    "/debug/nav",
    "/debug/nav2",
    "/debug/form2",
    "/debug/query-string-toast",
    "/debug/replay2",
    "/debug/replay-static",
    "/debug/timer",
    "/debug/master-detail",
    "/debug/react-table",
    "/debug/typing-test2",
  ]

  return (
    <Wrap spacing={2}>
      <WrapItem>
        <Button as={Link} to="/404">
          404
        </Button>
      </WrapItem>
      {links.map(link => (
        <WrapItem key={link}>
          <Button as={Link} to={link} activeStyle={{ border: "1px solid red" }}>
            {link.replace("/debug/", "")}
          </Button>
        </WrapItem>
      ))}
    </Wrap>
  )
}

function Env({ k, v }: { k: string; v?: string }) {
  return (
    <>
      <Text as="pre" fontSize="xs" opacity={0.4}>
        {k}
      </Text>
      <pre>{v || "-"}</pre>
    </>
  )
}

function EnvVars() {
  return (
    <Box overflowX="scroll">
      <Env k="NODE_ENV" v={process.env.NODE_ENV} />
      {/* Not displayed in prod, but is used to setup sentry during build */}
      <Env k="SENTRY_DSN" v={process.env.SENTRY_DSN} />
      {/* These don't work in prod or locally: */}
      {/* <Env
        k="VERCEL_GITHUB_COMMIT_SHA"
        v={process.env.VERCEL_GITHUB_COMMIT_SHA}
      />
      <Env k="VERCEL_GIT_COMMIT_SHA" v={process.env.VERCEL_GIT_COMMIT_SHA} /> */}
      <Env
        k="GATSBY_VERCEL_GIT_COMMIT_SHA"
        v={process.env.GATSBY_VERCEL_GIT_COMMIT_SHA}
      />
      <Env
        k="GATSBY_FIREBASE_PROJECT_ID"
        v={process.env.GATSBY_FIREBASE_PROJECT_ID}
      />
      <Env
        k="GATSBY_FIREBASE_API_KEY"
        v={process.env.GATSBY_FIREBASE_API_KEY}
      />
    </Box>
  )
}

export const H: FC = ({ children }) => {
  return (
    <Heading as="h6" size="xs" borderTop="2px solid currentColor" pt={1}>
      {children}
    </Heading>
  )
}

export function DebugContent() {
  return (
    <VStack spacing={6} align="stretch">
      <H>Account</H>
      <NoSsr>
        <DebugAuth />
      </NoSsr>
      <H>Pages</H>
      <Pages />
      <H>Environment Variables</H>
      <EnvVars />
      <H>Preferences</H>
      <Authenticated2 noAuth={<>N/A</>}>
        <Preferences />
      </Authenticated2>
      <H>Analytics</H>
      <DebugMixpanel />
      <H>Sentry</H>
      <DebugSentry />
    </VStack>
  )
}

function DebugButtonAndDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const { setShowDebug } = useDebug()

  return (
    <>
      <Box bottom="0" right="0" left="100%" position="sticky" display="flex">
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <Button
          as={Link}
          to="/debug"
          isFullWidth
          colorScheme="blue"
          borderRadius="0"
        >
          ⚠️ Debug Page
        </Button>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <Button
          colorScheme="orange"
          onClick={onOpen}
          isFullWidth
          borderRadius="0"
        >
          ⚠️ Debug Panel
        </Button>
      </Box>
      <Drawer
        size="sm"
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        motionPreset="none"
      >
        <DrawerOverlay>
          <DrawerContent>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader fontSize="md" borderBottomWidth="1px">
              Debug
            </DrawerHeader>

            <DrawerBody py={6}>
              <DebugContent />
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
              <ButtonGroup width="100%">
                <Button as={Link} to="/debug" isFullWidth>
                  Debug Page
                </Button>
                <Button
                  colorScheme="orange"
                  isFullWidth
                  onClick={() => setShowDebug(false)}
                >
                  ⊗ Debug
                </Button>
              </ButtonGroup>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export function Debug() {
  const { showDebug } = useDebug()
  const { pathname } = useLocation()

  if (pathname.startsWith("/debug")) return null
  if (!showDebug) return null

  return <DebugButtonAndDrawer />
}
