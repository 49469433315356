import React from "react"
import { Button } from "@chakra-ui/button"
import { useFirebase } from "../auth/FirebaseContext"
import { Wrap, WrapItem } from "@chakra-ui/layout"

export function DebugAuth() {
  const app = useFirebase()

  if (!app) return null

  return (
    <Wrap spacing={2}>
      <WrapItem>
        {app.auth().currentUser ? (
          <Button onClick={() => app.auth().signOut()}>Sign Out</Button>
        ) : (
          <Button
            onClick={() =>
              app
                .auth()
                .signInWithEmailAndPassword(
                  "markmiro+typing@hey.com",
                  "qmGe@-Ht3g7JEM7ba"
                )
            }
          >
            Sign In
          </Button>
        )}
      </WrapItem>
      <WrapItem>{app.auth().currentUser?.email}</WrapItem>
    </Wrap>
  )
}
