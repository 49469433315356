import React from "react"
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  EASINGS,
  Fade,
  Spacer,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

export function UnAuthenticatedAlert() {
  return (
    <Fade
      in
      variants={{
        exit: {
          opacity: 0,
          scale: 0.5,
          transition: {
            duration: 0.1,
            ease: EASINGS.easeOut,
          },
        },
        enter: {
          opacity: 1,
          scale: 1,
          transition: { type: "spring", duration: 0.7, bounce: 0.5 },
        },
      }}
    >
      <Alert status="warning">
        <AlertIcon />
        <AlertTitle>Authentication Required</AlertTitle>
        <Spacer />
        <Button as={GatsbyLink} to="/account/login" colorScheme="orange">
          Log In
        </Button>
      </Alert>
    </Fade>
  )
}

// {/* @ts-expect-error Not adding all required props to AlertDialog because alert is being used as a static element */}
// <AlertDialog isOpen isCentered size="5xl" motionPreset="slideInBottom">
// <AlertDialogOverlay
//   style={{
//     backdropFilter: "blur(10px)",
//   }}
// />
// <AlertDialogContent
//   minWidth="100vw"
//   borderRadius={0}
//   // background="transparent"
//   boxShadow="0"
// >
//   <Container>
//     <VStack spacing={4} p={8} textAlign="center">
//       <Text fontSize="5xl" lineHeight="1">
//         🔒
//       </Text>
//       {/* <Heading fontSize="4xl">Please log in to view this page.</Heading> */}
//       <Button
//         as={GatsbyLink}
//         to="/account/login"
//         colorScheme="blue"
//         size="lg"
//         isFullWidth
//       >
//         Log In for Access
//       </Button>
//       <div>
//         <Link to="/" as={GatsbyLink}>
//           Go Home
//         </Link>
//         {" · "}
//         <HelpLink />
//       </div>
//     </VStack>
//   </Container>
// </AlertDialogContent>
// </AlertDialog>
