import React from "react"

/**
 * Component always throws an error. Good for testing.
 * Also good for including inside a React tree
 */
export function ThrowError({ error }: { error?: Error }) {
  if (error) {
    throw error
  }

  throw new Error("No error message provided.")

  return null
}
