import { useColorMode, useTheme } from "@chakra-ui/react"

// Based on Tomorrow Theme
// https://github.com/reduxjs/redux-devtools/blob/75322b15ee7ba03fddf10ac3399881e302848874/src/react/themes/tomorrow.js
export function useChakraBase16() {
  // Transparent main background
  // const base00 = "rgba(0, 0, 0, 0)"
  // const base00 = useColorModeValue("gray.50", "gray.800")
  const theme = useTheme()
  const { colorMode } = useColorMode()
  const c = (light: any, dark: any) => (colorMode === "light" ? light : dark)
  const base00 = colorMode === "light" ? "white" : theme.colors.gray[800]
  const base01 = theme.colors.gray[c(200, 700)]
  const base02 = theme.colors.gray[c(300, 600)]
  const base03 = theme.colors.gray[c(400, 500)]
  const base04 = theme.colors.gray[c(500, 400)]
  const base05 = theme.colors.gray[c(600, 300)]
  const base06 = theme.colors.gray[c(700, 200)]
  const base07 = theme.colors.gray[c(800, 100)]
  const base08 = theme.colors.red[c(500, 300)]
  const base09 = theme.colors.orange[c(500, 300)]
  const base0A = theme.colors.yellow[c(500, 300)]
  const base0B = theme.colors.green[c(600, 300)]
  const base0C = theme.colors.cyan[c(700, 200)]
  const base0D = theme.colors.blue[c(700, 300)]
  const base0E = theme.colors.purple[c(600, 300)]
  const base0F = theme.colors.pink[c(600, 300)]

  return {
    scheme: `chakra-markmiro-${colorMode}`,
    author: "Mark Miro",
    base00,
    base01,
    base02,
    base03,
    base04,
    base05,
    base06,
    base07,
    base08,
    base09,
    base0A,
    base0B,
    base0C,
    base0D,
    base0E,
    base0F,
  }
}
