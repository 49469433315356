// Got some inspiration from here:
// https://invertase.io/blog/firebase-with-gatsby

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import { config } from "../../config"
import { useNoSsr } from "../NoSsrContext"

type SharedFirebaseType = {
  app: firebase.app.App | null
  user: firebase.User | null
  isLoadingUser: boolean
  init: () => void
}

export const SharedFirebaseContext = createContext<SharedFirebaseType>({
  app: null,
  user: null,
  isLoadingUser: false,
  init: () => {
    throw new Error("Please override init() func")
  },
})

export function getFirebaseApp() {
  if (firebase.apps.length === 0) {
    return firebase.initializeApp(config.firebase)
  } else {
    return firebase.apps[0]
  }
}

export const SharedFirebaseProvider: FC = ({ children }) => {
  const mixpanel = useMixpanel()
  const [didInit, setInit] = useState(false)
  const [app, setApp] = useState<firebase.app.App | null>(null)
  const [user, setUser] = useState<firebase.User | null>(null)
  const [isLoadingUser, setIsLoadingUser] = useState(true)

  useEffect(() => {
    if (!didInit) return
    if (!app) return

    const unregisterAuthChange = app.auth().onAuthStateChanged(user => {
      console.log("update user")
      setUser(user)
      // Note: Mixpanel doesn't remember actions by the same user that happened before signup
      // https://mixpanel.com/blog/best-practices/
      // From website: "A common problem is what happens when you have a user who isn’t signed up yet and then eventually does. It would be nice to remap the unique identifier. Unfortunately, we don’t have this functionality quite yet."
      if (user) {
        mixpanel.identify(user.uid)
        mixpanel.people.set({
          $email: user.email,
          // user.metadata.creationTime = "Thu, 11 Feb 2021 01:30:27 GMT"
          "Sign up date":
            user.metadata.creationTime && new Date(user.metadata.creationTime),
          USER_ID: user.uid,
        })
      }
      setIsLoadingUser(false)
    })

    const unregisterIdChange = app.auth().onIdTokenChanged(user => {
      console.log("update user")
      setUser(user)
      setIsLoadingUser(false)
    })

    return () => {
      unregisterAuthChange && unregisterAuthChange()
      unregisterIdChange && unregisterIdChange()
    }
  }, [didInit, app, mixpanel])

  function init() {
    if (didInit) return
    setInit(true)
    setApp(getFirebaseApp())
  }

  return (
    <SharedFirebaseContext.Provider value={{ app, user, isLoadingUser, init }}>
      {children}
    </SharedFirebaseContext.Provider>
  )
}

export function useFirebase() {
  useNoSsr()

  const { init, app } = useContext(SharedFirebaseContext)

  useEffect(init, [init])

  return app
}

export function useUser() {
  useNoSsr()

  const { init, app, user, isLoadingUser: isLoading } = useContext(
    SharedFirebaseContext
  )

  useEffect(init, [init])

  return { app, user, isLoading }
}
