import { Spinner, VStack } from "@chakra-ui/react"
import React from "react"

export function BigMessageSpinner({ message }: { message?: string }) {
  return (
    <VStack spacing={2}>
      <Spinner size="xl" />
      {message && <span>{message}</span>}
    </VStack>
  )
}
