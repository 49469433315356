import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import { DisabledWhenLoading } from "../DisabledWhenLoading"
import { Json } from "../Json"
import { usePrefsOld } from "../typing-test/usePrefsOld"

export function SelectTimerLength({
  value,
  onChange,
}: {
  value: number
  onChange: (n: number) => void
}) {
  return (
    <FormControl id="select-timer-length">
      <FormLabel>Timer length</FormLabel>
      <Select
        size="lg"
        // placeholder="Select option"
        value={value}
        onChange={e => onChange(parseInt(e.target.value))}
      >
        <option value="3">3 seconds</option>
        <option value="10">10 seconds</option>
        <option value="30">30 seconds</option>
        <option value="60">60 seconds</option>
      </Select>
    </FormControl>
  )
}

export function Preferences() {
  const { prefs, updatePrefs, resetPrefs, isLoading } = usePrefsOld()

  function updateTimerLength(n: number) {
    updatePrefs({ timerLengthSeconds: n })
  }

  return (
    <DisabledWhenLoading isLoading={isLoading}>
      <VStack spacing={3} align="start">
        <Text fontSize="xs">
          <Json>{{ prefs }}</Json>
        </Text>
        <SelectTimerLength
          value={prefs.timerLengthSeconds}
          onChange={updateTimerLength}
        />
        <Button onClick={resetPrefs}>Reset Preferences</Button>
      </VStack>
    </DisabledWhenLoading>
  )
}
