import { Box, BoxProps } from "@chakra-ui/react"
import { pick } from "lodash-es"
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"

const DebugContext = createContext({
  showDebug: false,
  setShowDebug: (v: boolean) => {},
})

export const DebugProvider: FC = ({ children }) => {
  const [showDebug, setShowDebug] = useState(false)

  // Trigger debug on keyboard shortcut
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      // console.log(
      //   pick(e, ["key", "metaKey", "controlKey", "ctrlKey", "altKey"])
      // )

      // Shortcuts for opening devtools
      // https://balsamiq.com/support/faqs/browserconsole/#:~:text=To%20open%20the%20developer%20console,(on%20Windows%2FLinux).

      // // Chrome macOS devtools shortcuts
      // const cmdOptionI = e.key === "Dead" && e.metaKey && e.altKey
      // const cmdOptionJ = e.key === "∆" && e.metaKey && e.altKey

      // // Chrome Windows devtools shortcuts
      // const ctrlShiftI = e.key === "I" && e.ctrlKey && e.shiftKey
      // const ctrlShiftJ = e.key === "J" && e.ctrlKey && e.shiftKey

      // if (cmdOptionI || cmdOptionJ || ctrlShiftI || ctrlShiftJ) {
      //   setShowDebug(true)
      // }

      const cmdK = e.key === "k" && e.metaKey
      const ctrlK = e.key === "k" && e.ctrlKey
      if (cmdK || ctrlK) setShowDebug(s => !s)
    }

    window.addEventListener("keydown", onKeyDown)
    return () => window.removeEventListener("keydown", onKeyDown)
  }, [])

  // Create a global window function for setting debug
  // useEffect(() => {
  //   // @ts-expect-error adding stuff to window object for debugging
  //   window.setShowDebug = s => setShowDebug(s)
  // }, [])

  return (
    <DebugContext.Provider value={{ showDebug, setShowDebug }}>
      {children}
    </DebugContext.Provider>
  )
}

export function useDebug() {
  const { showDebug, setShowDebug } = useContext(DebugContext)

  return {
    showDebug,
    setShowDebug,
  }
}

export const DebugOnly: FC<BoxProps> = ({ children, ...rest }) => {
  const { showDebug } = useDebug()
  if (!showDebug) return null
  return (
    <Box borderWidth="1px" borderColor="orange.500" {...rest}>
      {children}
    </Box>
  )
}
