import { PrefsType } from "./types"
/**
 * 🚨 IMPORTANT: Also update in these locations:
 * - /static/favicon.svg
 * - https://console.firebase.google.com => Project settings => Public-facing name
 * - https://console.firebase.google.com => Authentication => Templates => noreply@typing-test-dev.firebaseapp.com (requires DNS change)
 */
const branding = {
  // SITE_EMOJI: "⌨️",
  // SITE_NAME: "Typing Test",
  SITE_EMOJI: "🦀",
  SITE_NAME: "Crabby Keys",
  HELP_EMAIL: "typingtest@markmiro.com",
}

const TIMER_LENGTH = 30
const RANDOM_TOP_WORDS = 250

const defaultPrefs: PrefsType = {
  ui: "with-cursor",
  durationSeconds: TIMER_LENGTH,
  vocabSize: RANDOM_TOP_WORDS,
  showErrorsWhileTyping: true,
  smoothCursor: true,
}

export const config = {
  ...branding,
  /** In seconds */
  TIMER_LENGTH,
  /** How many of the top words are searched */
  RANDOM_TOP_WORDS,
  /** Assume nobody can type faster than 250 wpm */
  RANDOM_WORD_COUNT: Math.round((250 / 60) * TIMER_LENGTH),
  defaultPrefs,
  //
  firebase: {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  },
}
