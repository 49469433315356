import { ChakraProvider } from "@chakra-ui/react"
import React, { StrictMode } from "react"
import { SharedFirebaseProvider } from "./src/components/auth/FirebaseContext"
import { ErrorBoundary } from "./src/components/ErrorBoundary"
import { DebugProvider } from "./src/components/debug/DebugContext"
import { Debug } from "./src/components/debug/Debug"
import { theme } from "./theme"
import { LocationProvider } from "@reach/router"

export const wrapRootElement = ({ element }) => {
  return (
    // This loads firebase and user data. Most pages need this. It's possible to just wrap every page in this provider too
    // One benefit of keeping it hoisted up this high is it's guaranteed to be in the same place in the React tree
    // and it won't need to re-initialize itself across route changes
    <LocationProvider>
      <ErrorBoundary>
        <StrictMode>
          <DebugProvider>
            <SharedFirebaseProvider>
              <ChakraProvider theme={theme}>
                {element}
                <Debug />
              </ChakraProvider>
            </SharedFirebaseProvider>
          </DebugProvider>
        </StrictMode>
      </ErrorBoundary>
    </LocationProvider>
  )
}
