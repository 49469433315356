import React, { createContext, FC, useContext } from "react"

export const SsrContext = createContext<false | null>(null)

/**
 * Use this to ensure there's a parent that defines SsrContext.Provider.
 * This is useful to make Gatsby builds fail if this is not handled properly.
 */
export function useNoSsr() {
  const ssr = useContext(SsrContext)
  if (ssr === null) {
    throw new Error("`useNoSsr` must be used within a `NoSsr` provider")
  }
}

export const NoSsr: FC = ({ children }) => {
  const ssr = typeof window === "undefined"
  if (ssr) return null

  return <SsrContext.Provider value={ssr}>{children}</SsrContext.Provider>
}

// Normally, HOC is bad:
// https://www.robinwieruch.de/react-hooks-higher-order-components
// However, this doesn't set props, just wraps the component
/** HOC that just wraps component */
export function noSsr<P>(WrappedComponent: FC<P>) {
  return (props: P) => (
    <NoSsr>
      <WrappedComponent {...props} />
    </NoSsr>
  )
}
