import { Box, useColorModeValue } from "@chakra-ui/react"
import React from "react"
import JSONTree from "react-json-tree"
import { useChakraBase16 } from "./useChakraBase16"

export function Json({
  children,
  isAdvanced = false,
  expandDepth = Infinity,
}: {
  children: any
  isAdvanced?: boolean
  expandDepth?: number
}) {
  const theme = useChakraBase16()
  const borderColor = useColorModeValue("gray.200", "gray.700")

  if (isAdvanced) {
    return (
      <Box
        fontFamily="mono"
        bg={theme.base00}
        px={5}
        pb="0.25em"
        rounded="md"
        borderWidth={1}
        borderColor={borderColor}
      >
        <JSONTree
          data={children}
          theme={theme}
          invertTheme={false}
          shouldExpandNode={(keyPath, data, level) => level < expandDepth}
        />
      </Box>
    )
  }

  return <pre>{JSON.stringify(children, null, "  ")}</pre>
}
