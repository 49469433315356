import { extendTheme } from "@chakra-ui/react"

export const theme = extendTheme({
  config: {
    // TODO: Fix flashing using this approach:
    // https://blog.maximeheckel.com/posts/switching-off-the-lights-part-2-fixing-dark-mode-flashing-on-servered-rendered-website
    // If `initialColorMode` isn't "dark", it causes flashing in Safari
    // If `useSystemColorMode` is set, user can't modify the color mode across refreshes
    initialColorMode: "system",
    // useSystemColorMode: true,
  },
  styles: {
    global: {
      body: {
        transition: "background-color 0s",
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: "underline",
      },
    },
  },
})
